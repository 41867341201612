<template>
  <div class="mall-left">
    <p 
      class="item f-hide"
      v-for="item in list"
      :key="item.id"
      :class="{'active': currentItem == item.id}"
      @click="selectFn(item)"
    >{{item.dictName}}</p>
  </div>
</template>

<script>
import { reqDictType } from "@/api/hy";
export default {
  data () {
    return {
      list: [],
      currentItem: ''
    }
  },
   watch: {
    $route: {
      handler(val,onlval){
        console.log(val);
        // 在mounted函数执行的方法，放到该处
        this.currentItem =''
      },
      deep:true
        
    },
  },
  mounted () {
    this.getType()
  },
  methods: {
    selectFn (item) {
      this.currentItem = item.id
      this.$emit('select',item.id)
    },
    getType() {
      reqDictType({dictType: 'coal_type'}).then((ret)=>{
        if (ret.code == 0) {
          this.list = ret.data.children
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mall-left{
  text-align: center;
  line-height: 40px;
  background: white;
  .item{
    cursor: pointer;
    width: 184px;
    &.active{
      color: #096AFF
    }
  }

}
</style>